export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0,2];

export const dictionary = {
		"/[lang]": [8,[2]],
		"/[lang]/about/personal-info/bio": [~9,[2,3,4,5]],
		"/[lang]/about/personal-info/bio/looking-for": [~10,[2,3,4,5]],
		"/[lang]/about/personal-info/education/projects": [~11,[2,3,4,6]],
		"/[lang]/about/personal-info/education/schools": [~12,[2,3,4,6]],
		"/[lang]/about/personal-info/hobbies": [~13,[2,3,4]],
		"/[lang]/about/professional-info/experiences": [~14,[2,3,7]],
		"/[lang]/about/professional-info/languages": [~15,[2,3,7]],
		"/[lang]/about/professional-info/skills": [~16,[2,3,7]],
		"/[lang]/contact": [~17,[2]],
		"/[lang]/legals/legals-mentions": [~18,[2]],
		"/[lang]/projects": [~19,[2]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';